import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';
import { BasePage } from '../BasePage';
import Table from '../../table/Table';
import { format, getBrowserTimezone } from '../../../utils/formatting';
import { getSwarmInfoById } from '../measuringPoint/utils';
import useOrderAndPaginationControl from '../../table/hooks';
import useSoundClipQueryGenerator from './utils';
import { LoadingPage } from '../LoadingPage';

const loadingState = Symbol('loading');

const pageSize = 10;

function SoundClipsPage() {
    const { id, startTime, endTime } = useParams();
    const orderAndPaginationControl = useOrderAndPaginationControl(pageSize);
    const [measuringPointName, setMeasuringPointName] = useState(loadingState);
    const [timezone, setTimezone] = useState(getBrowserTimezone());
    const { soundClips, loading } = useSoundClipQueryGenerator(
        orderAndPaginationControl,
        id,
        startTime,
        endTime
    );
    const title = useMemo(() => {
        const [startTimeHuman, endTimeHuman] = [startTime, endTime].map((timestamp) =>
            format(new Date(parseInt(timestamp, 10)), { timezone })
        );

        return interpolate(
            gettext('SOUND_CLIPS_PAGE_TITLE'),
            { measuringPointName, startTime: startTimeHuman, endTime: endTimeHuman },
            true
        );
    }, [measuringPointName, startTime, endTime, timezone]);

    const columns = useMemo(
        () => [
            {
                key: 'startTime',
                title: gettext('START_TIME'),
                showSortIcon: true,
                render: (column, row) => format(new Date(row.startTime), { timezone }),
            },
            {
                key: 'endTime',
                title: gettext('END_TIME'),
                showSortIcon: true,
                render: (column, row) => format(new Date(row.endTime), { timezone }),
            },
            {
                key: 'waveFile',
                title: gettext('SOUND_CLIP'),
                render: (column, row) => (
                    <div className="flex justify-end gap-4">
                        <audio controls className="h-8 w-72">
                            <source src={row.waveFile} type="audio/mpeg" />
                            {gettext('AUDIO_PLAYER_NOT_SUPPORTED')}
                        </audio>
                        <a
                            href={row.waveFile}
                            download
                            target="_blank"
                            className="hover:text-primary"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faCloudArrowDown}
                                className="h-8 w-8 fill-current"
                            />
                        </a>
                    </div>
                ),
            },
        ],
        [timezone]
    );

    useEffect(() => {
        // Let the UI know we are querying.
        setMeasuringPointName(loadingState);

        getSwarmInfoById(id, false).then((result) => {
            setMeasuringPointName(result.name);
            setTimezone(result.timezone.replace('_', '/'));
        });
    }, [id, setMeasuringPointName]);

    if (measuringPointName === loadingState) {
        return <LoadingPage />;
    }

    return (
        <BasePage title={title} besideTitle={`${gettext('TIMEZONE')}: ${timezone}`}>
            <div className="main-container-new pb-2">
                <Table
                    data={soundClips.map((edge) => edge.node)}
                    columns={columns}
                    loading={loading}
                    orderAndPaginationControl={orderAndPaginationControl}
                />
            </div>
        </BasePage>
    );
}

export default SoundClipsPage;
