import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { createAfterCursor } from '../../table/utils';

const SOUND_CLIP_FRAGMENT = gql`
    fragment SoundClipTypeFields on SoundClipType {
        id
        startTime
        endTime
        waveFile
    }
`;

const SOUND_CLIPS_QUERY = gql`
    query soundClips(
        $measuringPoint: ID!
        $startTime: BigInteger!
        $endTime: BigInteger!
        $orderBy: String
        $before: String
        $after: String
        $first: Int
        $last: Int
    ) {
        soundClips(
            measuringPoint: $measuringPoint
            startTime: $startTime
            endTime: $endTime
            orderBy: $orderBy
            before: $before
            after: $after
            first: $first
            last: $last
        ) {
            totalCount
            edges {
                node {
                    ...SoundClipTypeFields
                }
            }
            pageInfo {
                startCursor
                endCursor
            }
        }
    }
    ${SOUND_CLIP_FRAGMENT}
`;

export default function useSoundClipQueryGenerator(
    orderAndPaginationControl,
    id,
    startTime,
    endTime
) {
    const [soundClips, setSoundClips] = useState([]);

    const queryVariables = useMemo(() => {
        const { currentPage, pageSize, orderBy } = orderAndPaginationControl;

        return {
            measuringPoint: id,
            startTime,
            endTime,
            first: pageSize,
            after: createAfterCursor(currentPage, pageSize),
            orderBy,
        };
    }, [orderAndPaginationControl, id, startTime, endTime]);

    const { data, loading, networkStatus } = useQuery(SOUND_CLIPS_QUERY, {
        variables: queryVariables,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data) {
            setSoundClips(data.soundClips?.edges ?? []);
            orderAndPaginationControl.setTotalCount(data.soundClips?.totalCount);
        }
    }, [data, orderAndPaginationControl]);

    return {
        soundClips,
        loading: loading && networkStatus === NetworkStatus.loading,
    };
}
