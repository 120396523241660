import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import MiddleEllipsis from 'react-middle-ellipsis';
import Pagination from '../../table/Pagination';
import Table from '../../table/Table';
import { BasePage } from '../BasePage';
import useGraphReportQueryGenerator from './utils';
import Button from '../../form/Button';
import { formatFileSize, format } from '../../../utils/formatting';
import { DOWNLOAD_CATEGORY_TRANSLATIONS, DOWNLOAD_STATUS_TRANSLATIONS } from '../../enums';
import useOrderAndPaginationControl from '../../table/hooks';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

const pageSize = 10;

function formatLastDownloaded(date) {
    return format(date, {
        useBrowserTimezone: true,
        hideTime: true,
        dateFormat: 'dd MMM yyyy',
    });
}

export default function DownloadPage() {
    const orderAndPaginationControl = useOrderAndPaginationControl(pageSize);

    const { graphReports, downloadFile, loading, updating } =
        useGraphReportQueryGenerator(orderAndPaginationControl);

    const columns = useMemo(
        () => [
            {
                key: 'name',
                title: gettext('NAME'),
                showSortIcon: true,
                render: (_, { id, name }) => (
                    <div className="max-w-[90%] whitespace-nowrap" title={name}>
                        <MiddleEllipsis key={id}>
                            <span>{name}</span>
                        </MiddleEllipsis>
                    </div>
                ),
            },
            {
                key: 'size',
                title: gettext('SIZE'),
                showSortIcon: true,
                render: (_, { size }) => <p>{formatFileSize(size)}</p>,
            },
            {
                key: 'status',
                title: gettext('STATUS'),
                render: (_, { status }) => <p>{DOWNLOAD_STATUS_TRANSLATIONS[status]}</p>,
            },
            {
                key: 'category',
                title: gettext('CATEGORY'),
                render: (_, { category }) => <p>{DOWNLOAD_CATEGORY_TRANSLATIONS[category]}</p>,
            },
            {
                key: 'lastDownloaded',
                title: gettext('LAST_DOWNLOAD'),
                showSortIcon: true,
                render: (_, { lastDownloaded }) => (
                    <p>
                        {lastDownloaded === null
                            ? gettext('NEVER')
                            : formatLastDownloaded(lastDownloaded)}
                    </p>
                ),
            },
            {
                key: '',
                title: (
                    <span className="space-x-2">
                        {updating && <LoadingSpinner className="h-5 align-middle text-gray-400" />}
                        {orderAndPaginationControl.totalCount > pageSize && (
                            <Pagination orderAndPaginationControl={orderAndPaginationControl} />
                        )}
                    </span>
                ),
                render: (_, { id, url }) => (
                    <Button
                        variant={url !== '' ? 'downloadButton' : 'downloadButtonSecondary'}
                        onClick={() => downloadFile(id, url)}
                        disabled={url === ''}
                    >
                        {gettext('DOWNLOAD')} <FontAwesomeIcon icon={faDownload} />
                    </Button>
                ),
            },
        ],
        [downloadFile, orderAndPaginationControl, updating]
    );

    const title = gettext('DOWNLOAD_PAGE_TITLE');
    const nodes = useMemo(() => graphReports.map((edge) => edge.node), [graphReports]);

    return (
        <BasePage title={title}>
            <div className="main-container-new pb-2">
                <Table
                    data={nodes}
                    columns={columns}
                    header={title}
                    loading={loading}
                    orderAndPaginationControl={orderAndPaginationControl}
                />
            </div>
        </BasePage>
    );
}
